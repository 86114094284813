import { useI18n } from 'vue-i18n'

export default function breadcrumbInteraction() {
  const { t } = useI18n()

  /**
   * Breadcrumb for index page
   *
   * @returns {[{link: boolean, text: string, to: string}]}
   */
  function index() {
    return [
      {
        text: t('breadcrumbs.interactions.interactions'),
        link: false,
        to: '/interactions',
      },
    ]
  }

  return {
    index,
  }
}

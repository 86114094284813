<template>
  <div
    ref="appTableContainerRef"
  >
    <app-table
      :headers="headers"
      :data="tableData"
      :loading="loading"
    />
  </div>

  <app-pagination
    :links="links"
    :change-page-callback="handleChangePage"
    :element-to-scroll-to="appTableContainerRef"
    class="mt-5"
  />
</template>

<script setup>
import { computed, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'

import { displayName } from '@shared/helpers/user'
import useLocale from '@shared/hooks/locale'
import useDatetime from '@shared/hooks/datetime'
import {
  fetchInteractions as fetchApiInteractions,
} from '@shared/http/api'
import AppTable from '@extranet/components/ui/table/AppTable.vue'
import AppPagination from '@extranet/components/ui/AppPagination.vue'

const props = defineProps({
  // Mode used to fetch data
  mode: {
    type: String,
    required: true,
    validator(value) {
      // The value must match one of these strings
      return [
        'folder',
        'butler',
        'traveller',
        'manager',
      ].includes(value)
    },
  },
  // Parameter used to filter in
  // some modes
  filterId: {
    type: [String, Number],
    required: true,
  },
})

const { t } = useI18n()
const { getAttributeTranslation } = useLocale()
const {
  formatDateWithTime,
} = useDatetime()

// ---------- INTERACTION ----------

const interactions = ref([])
const loading = ref(true)

function fetchInteractions(nextPage = false) {
  if (nextPage === false) {
    loading.value = true
  }

  nextPageFetching.value = nextPage

  const params = {
    'page': page.value,
    'mode': props.mode,
    'filterId': props.filterId,
  }

  fetchApiInteractions(params)
    .then((response) => {
      interactions.value = response.data.data?.map((interaction) => ({
        resource: interaction,
      })) ?? []

      // Pagination links
      links.value = response.data.meta?.links ?? []
    })
    .finally(() => {
      nextPageFetching.value = false
      loading.value = false
    })
}

// ---------- DATA TABLE ----------

const headers = [
  { label: t('validation.attributes.description') },
  { label: t('validation.attributes.created_at') },
]

const tableData = computed(() => (
  interactions?.value?.map((interaction) => ({
    description: getDescription(interaction),
    created_at: formatDateWithTime(interaction.resource.attributes.created_at),
  }))
))

function getDescription(interaction) {
  return t(
    `interactions.description.${interaction.resource?.attributes?.interaction_type}`,
    {
      sender_name: displayName(interaction.resource?.relationships?.sender),
      recipient_name: displayName(interaction.resource?.relationships?.recipient),
      share: getShareTitle(interaction.resource?.relationships?.model),
    },
  )
}

function getShareTitle(model) {
  if (!model || model.type !== 'shares') {
    return null
  }

  const shareable = model?.relationships?.shareable
  const shareType = shareable?.type

  if (shareType) {
    const shareTitle = getAttributeTranslation(shareable.attributes.title)
    return `${t(`interactions.share_type.${shareType}`)} ${shareTitle}`
  }
}

// ---------- PAGINATION ----------

const appTableContainerRef = ref(null)
const nextPageFetching = ref(false)
const links = ref([])
const page = ref(1)

function handleChangePage(value) {
  page.value = value
  fetchInteractions()
}

// ---------- LIFECYCLES ----------

onMounted(() => {
  fetchInteractions()
})
</script>

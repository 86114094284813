<template>
  <teleport-wrapper
    to="#breadcrumbs"
  >
    <app-breadcrumb
      :breadcrumbs="breadcrumbs"
    />
  </teleport-wrapper>

  <interaction-table
    mode="manager"
    :filter-id="userId"
  />
</template>

<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'

import breadcrumbInteraction from '@extranet/breadcrumbs/interaction'
import AppBreadcrumb from '@extranet/components/ui/AppBreadcrumb.vue'
import InteractionTable from '@extranet/components/resources/interaction/InteractionTable.vue'

// ---------- BREADCRUMBS ----------

const { index } = breadcrumbInteraction()

const breadcrumbs = computed(() => (
  index()
))

// ---------- USER ID ----------

const store = useStore()

const userId = computed(() => (
  store.state.filters.interactions.user_id
))
</script>
